<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t('market.filters.title') }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label for="category">{{ $t('market.table.fields.category') }}</label>
          <v-select
            id="category"
            :value="categoryFilter"
            :multiple="false"
            :options="categoryOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:categoryFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label for="status">{{ $t('market.table.fields.status') }}</label>
          <v-select
            id="status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'

export default {
  mixins: [GlobalMixin],
  props: {
    categoryFilter: {
      type: [Number, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      categoryOptions: [],
    }
  },
  async beforeCreate() {
    await this.$http.get('/api/admin/categories', {
      params: {
        type: 'post',
        status: 'enabled',
      },
    })
      .then(response => {
        this.categoryOptions = this.$options.filters.transformForVSelect(response.data, 'id', 'title')
      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
